<template>
  <div>
    <v-overlay v-model="loadUpgrades">
      <v-progress-circular
        indeterminate
        size="40"
        color="primary"
      ></v-progress-circular>
    </v-overlay>

    <v-toolbar color="transparent" class="d-flex justify-center mb-n4" flat>
      <strong>Upgrade Your Account</strong>
    </v-toolbar>

    <small class="grey--text">
      Thanks for choosing to upgrade, please select a plan.
    </small>

    <v-row class="mt-4" justify="center">
      <v-col v-for="(upgrade, i) in upgrades" :key="i" cols="12" md="3">
        <v-card
          :class="{
            'rounded-xl': true,
            'px-3': true,
            'active-plan': user.account_type === upgrade.name,
          }"
          flat
        >
          <v-divider class="primary pad-line"></v-divider>
          <div class="pt-4">
            <strong>{{ upgrade.name.toUpperCase() }}</strong>
          </div>
          <div>
            <v-card-text class="text-h4 font-weight-bold primary--text">
              ${{ upgrade.amount }}
              <span class="grey--text text-h5">/ {{ upgrade.duration }}</span>
            </v-card-text>
          </div>
          <div class="text-left">
            <v-list-item dense>
              <v-icon color="primary">mdi-play-circle-outline</v-icon>
              <small class="ml-2">
                {{ upgrade.minutes }} minutes of recording
              </small>
            </v-list-item>
            <v-list-item dense :style="teamMembersAccount(upgrade)">
              <v-icon color="primary">mdi-account-group-outline</v-icon>
              <small class="ml-2">
                {{ upgrade.accounts > 0 ? "Up to " : "" }}
                {{ upgrade.accounts }} user accounts
              </small>
            </v-list-item>
            <v-list-item dense :style="customDomain(upgrade)">
              <v-icon color="primary">mdi-tune</v-icon>
              <small class="ml-2"> Custom branding </small>
            </v-list-item>
          </div>
          <v-card-actions class="pb-10 pt-4">
            <v-spacer></v-spacer>
            <v-btn
              v-if="upgrade.plan_type == 'standard'"
              @click="openCheckoutDialog(upgrade)"
              class="capitalize"
              color="primary"
              rounded
              :disabled="user.account_type === upgrade.name"
            >
              <small>Select plan</small>
            </v-btn>
            <v-btn
              v-if="upgrade.plan_type == 'appsumo'"
              @click="appSumoUpgradeLink()"
              class="capitalize"
              color="primary"
              rounded
              :disabled="user.account_type === upgrade.name"
            >
              <small>Upgrade</small>
            </v-btn>
            <v-btn
              v-if="upgrade.plan_type == 'lifetime'"
              @click="lifetimeCheckout(upgrade)"
              class="capitalize"
              color="primary"
              rounded
              :disabled="user.account_type === upgrade.name"
            >
              <small>Select plan</small>
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="checkoutDialog" width="900">
      <v-card class="dialog-billing-period">
        <v-card-title class="text-h5 lighten-2 mb-4 justify-center">
          Select Your Billing Period
        </v-card-title>

        <v-row class="mb-4" justify="center">
          <!-- Dropdown to select different plans -->
          <v-col cols="12" md="4">
            <v-select
              v-model="selectedPlanId"
              :items="upgrades"
              item-text="name"
              item-value="id"
              label="Select Plan"
              dense
              solo
            ></v-select>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12" md="5">
            <!-- Monthly Plan -->
            <v-card
              :class="{ 'selected-plan': selectedBillingPeriod === 'Monthly' }"
              outlined
            >
              <v-radio-group v-model="selectedBillingPeriod" class="pt-4">
                <v-radio
                  label="1 MONTH"
                  value="Monthly"
                  class="plan-box"
                ></v-radio>
                <v-card-text class="text-h4 text-center font-weight-bold">
                  ${{ selectedPlan?.monthlyPrice }}
                </v-card-text>
                <h5 class="text-center mt-n3">USD/Month</h5>
                <div class="grey--text text-center mt-2">
                  Plan renews at ${{ selectedPlan?.monthlyPrice }}/month<br />on
                  {{ monthlyRenewalDate }}
                </div>
              </v-radio-group>
            </v-card>
          </v-col>

          <v-col cols="12" md="5">
            <!-- Yearly Plan -->
            <v-card
              :class="{ 'selected-plan': selectedBillingPeriod === 'Yearly' }"
              outlined
            >
              <v-radio-group v-model="selectedBillingPeriod" class="pt-4">
                <v-radio
                  label="12 MONTHS"
                  value="Yearly"
                  class="plan-box"
                ></v-radio>
                <v-card-text class="text-h4 text-center font-weight-bold">
                  ${{ selectedPlan?.yearlyPrice }}
                </v-card-text>
                <h5 class="text-center mt-n3">USD/Year</h5>
                <div class="grey--text text-center mt-2">
                  Plan renews at ${{ selectedPlan?.yearlyPrice }}/year<br />on
                  {{ yearlyRenewalDate }}
                </div>
              </v-radio-group>
            </v-card>
          </v-col>
        </v-row>

        <v-card-actions class="pt-4">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="checkout-proceed-btn"
            @click="proceedToCheckout"
            >Proceed</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ResponseHelper from "../../utils/ResponseHelper";
import usePaddle from "../../composables/usePaddle";
import { mapGetters } from "vuex";

export default {
  name: "AccountUpgrade",
  data() {
    return {
      loadUpgrades: false,
      upgrades: [],
      toast: new ResponseHelper(),
      checkoutDialog: false,
      selectedPlanId: null,
      selectedBillingPeriod: "Monthly",
      billingPeriods: ["Monthly", "Yearly"],
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    selectedPlan() {
      return this.upgrades.find(
        (upgrade) => upgrade.id === this.selectedPlanId
      );
    },
    monthlyRenewalDate() {
      return this.calculateRenewalDate(1); // 1 month
    },
    yearlyRenewalDate() {
      return this.calculateRenewalDate(12); // 12 months
    },
  },
  async created() {
    await this.getUpgrades();
    await this.refreshPlansDetails();

    const { initializeOverlayPaddleInstance } = usePaddle();
    await initializeOverlayPaddleInstance(this.handlePaymentSuccess);
  },
  methods: {
    async refreshPlansDetails() {
      const response = await this.$axios.get(
        `/upgrades/${this.user.email}/get-user-plans-details`
      );
      this.$store.commit("auth/USER_DATA", response.data, { root: true });
    },
    async getAuthUserFullDetails() {
      const response = await this.$axios.get(
        `/upgrades/${this.user.email}/get-auth-user-full-details`
      );
      return response;
    },
    async handlePaymentSuccess(data) {
      const totalAmount = Math.round(data.data.items[0].totals.total);
      const monthlyPrice = Math.round(this.selectedPlan?.monthlyPrice);
      const yearlyPrice = Math.round(this.selectedPlan?.yearlyPrice);

      const authUserDtlsResponse = await this.getAuthUserFullDetails();
      let planType = authUserDtlsResponse.data.payments[0].upgrade.plan_type;

      if (
        totalAmount === monthlyPrice ||
        totalAmount === yearlyPrice ||
        planType === "lifetime"
      ) {
        try {
          if (data.name === "checkout.completed") {
            const response = await this.$axios.get(
              `/upgrades/${data.data.customer.email}/get-user-plans-details`
            );
            this.$store.commit("auth/USER_DATA", response.data, { root: true });
            this.selectedPlan = null;
            window.location.href = "/payment-successful";
          }
        } catch (e) {
          this.toast.sendError(e);
        }
      }
    },
    async getUpgrades() {
      this.loadUpgrades = true;
      const authUserDtlsResponse = await this.getAuthUserFullDetails();
      let planType = authUserDtlsResponse.data.active_plan.plan_type;
      let endpoint;
      switch (planType) {
        case "standard":
          endpoint = "/upgrades/get-konvey-standard-plans";
          break;
        case "appsumo":
          endpoint = "/upgrades/get-konvey-appsumo-plans";
          break;
        case "lifetime":
          endpoint = "/upgrades/get-konvey-lifetime-plans";
          break;
        default:
          endpoint = "/upgrades/get-konvey-standard-plans";
      }
      try {
        const response = await this.$axios.get(endpoint);
        console.log("FETCHED DATA ==>", response);
        this.upgrades = response.data
          .map((upgrade) => ({
            ...upgrade,
            monthlyPrice: upgrade.amount, // monthly amount
            yearlyPrice: upgrade.amount * 12 * 0.75, // 25% off for yearly
          }))
          .sort((a, b) => a.amount - b.amount);
        this.loadUpgrades = false;
      } catch (e) {
        this.loadUpgrades = false;
        this.toast.sendError(e);
        console.log("ERROR FETCHING UPGRADES ==>", e);
      }
    },
    openCheckoutDialog(upgrade) {
      this.selectedPlanId = upgrade.id;
      this.selectedBillingPeriod = "Monthly"; // Reset to default on dialog open
      this.checkoutDialog = true;
    },
    proceedToCheckout() {
      const { openOverlayCheckout } = usePaddle();
      const k_billing_price_id =
        this.selectedBillingPeriod === "Monthly"
          ? this.selectedPlan?.price_id
          : this.selectedPlan?.annual_price_id;
      openOverlayCheckout(k_billing_price_id, this.user.email);
      this.checkoutDialog = false;
    },
    lifetimeCheckout(upgrade) {
      const { openOverlayCheckout } = usePaddle();
      const k_billing_price_id = upgrade.price_id;
      openOverlayCheckout(k_billing_price_id, this.user.email);
      this.checkoutDialog = false;
    },
    appSumoUpgradeLink() {
      window.open("https://appsumo.com/products/konvey", "_blank");
    },
    customDomain(upgrade) {
      return ["yes", "Yes"].includes(upgrade.custom_branding)
        ? ""
        : "text-decoration: line-through";
    },
    teamMembersAccount(upgrade) {
      return upgrade.accounts > 0 ? "" : "text-decoration: line-through";
    },
    calculateRenewalDate(months) {
      const renewalDate = new Date();
      renewalDate.setMonth(renewalDate.getMonth() + months);
      return renewalDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });
    },
  },
};
</script>

<style scoped>
.pad-line {
  padding: 1px 0;
  width: 20%;
  margin: auto;
}
.active-plan {
  border: 4px solid green;
}
.selected-plan {
  border: 2px solid blue;
}
/*  */
.dialog-billing-period {
  padding: 30px 80px;
}
.checkout-proceed-btn {
  width: 200px;
  border-radius: 6px;
  text-transform: capitalize;
  font-size: 16px;
  margin-top: 20px;
}
.plan-box {
  margin-left: 80px;
}
</style>
