import { initializePaddle } from "@paddle/paddle-js";
import CryptoJS from "crypto-js";

const secretKey = process.env.VUE_APP_PADDLE_SALT;

const paddleToken = process.env.VUE_APP_PADDLE_TOKEN;

const paddleEnv = process.env.VUE_APP_PADDLE_ENVIRONMENT;

function encryptToken(token) {
  return CryptoJS.AES.encrypt(token, secretKey).toString();
}

function decryptToken(encryptedToken) {
  const bytes = CryptoJS.AES.decrypt(encryptedToken, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
}

let overlayPaddleInstance = null;
let inlinePaddleInstance = null;

// retrieve local details
const auth = JSON.parse(localStorage.getItem("user"));

let cusEmail = null;
let isAllowLogout = true;
if (auth && auth.email) {
  cusEmail = auth.email;
  isAllowLogout = false;
}

export default function usePaddle() {
  return {
    async initializeOverlayPaddleInstance(successCallbackArg) {
      const decryptedToken = decryptToken(encryptToken(paddleToken));
      overlayPaddleInstance = await initializePaddle({
        environment: paddleEnv,
        token: decryptedToken,
        eventCallback: successCallbackArg,
        checkout: {
          settings: {
            displayMode: "overlay",
            theme: "light",
            locale: "en",
            allowLogout: false,
            showAddDiscounts: true,
          },
        },
      });
      return overlayPaddleInstance;
    },

    async initializeInlinePaddleInstance() {
      // Fetch the latest plan name just before checkout starts
      const planName = (localStorage.getItem("plan_name") || "").trim();

      // Define the redirect map
      const redirectMap = {
        "Personal Lifetime": "https://offers.konvey.app/upgrade",
        "Commercial Lifetime": "https://offers.konvey.app/upgrade",
        "Business Lifetime": "https://offers.konvey.app/pro-account",
        "Enterprise Lifetime": "https://offers.konvey.app/pro-account",
        "PRO Basic Lifetime": "https://offers.konvey.app/thank-you-page",
        "PRO Premium Lifetime": "https://offers.konvey.app/thank-you-page",
      };

      // Ensure the latest redirect URL is used
      const redirectUrl =
        redirectMap[planName] || "https://offers.konvey.app/special-deal";

      const decryptedToken = decryptToken(encryptToken(paddleToken));

      inlinePaddleInstance = await initializePaddle({
        environment: paddleEnv,
        token: decryptedToken,
        checkout: {
          settings: {
            displayMode: "inline",
            allowLogout: isAllowLogout,
            theme: "light",
            locale: "en",
            successUrl: redirectUrl,
            frameTarget: "checkout-container",
            frameInitialHeight: "450",
            frameStyle:
              "width: 100%; min-width: 312px; background-color: transparent; border: none;",
          },
        },
      });
      return inlinePaddleInstance;
    },

    openOverlayCheckout(productId, email) {
      if (overlayPaddleInstance) {
        overlayPaddleInstance.Checkout.open({
          items: [{ priceId: productId, quantity: 1 }],
          customer: { email },
        });
      } else {
        console.error("Paddle instance is not initialized.");
      }
    },

    openInlineCheckout(productId) {
      if (inlinePaddleInstance) {
        const checkoutOptions = {
          items: [{ priceId: productId, quantity: 1 }],
        };

        if (cusEmail) {
          checkoutOptions.customer = {
            email: cusEmail,
          };
        }

        inlinePaddleInstance.Checkout.open(checkoutOptions);
      } else {
        console.error("Paddle inline instance is not initialized.");
      }
    },

    encryptToken(token) {
      return encryptToken(token);
    },
  };
}
